<template>
    <BlockUI :blocked="loading" :fullScreen="true" style="position:absolute;top:50%;left:50%;">
        <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
    </BlockUI>
    <TabView class="p-mt-2">
        <TabPanel header="Datos del titular">
            <div class="p-grid p-holder">
                <Fieldset legend="Datos personales" :toggleable="true" class="p-col-12">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-3">
                            <div class="p-field">
                                <span class="p-float-label">
                                    <input-text id="ced" type="text" v-model="data.dni"
                                                :class="{'p-invalid': submitted && !data.dni }" class="p-inputtext-sm"
                                                :disabled="!edit"/>
                                    <label for="ced">Cédula</label>
                                </span>
                                <small class="p-invalid" v-if="submitted && !data.dni">La cédula es requerida</small>
                            </div>
                            <div class="p-field">
                                <span class="p-float-label">
                                    <InputText id="mail" type="text" v-model="data.email"
                                               :class="{'p-invalid': submitted && !data.email}" class="p-inputtext-sm"
                                               :disabled="!edit"/>
                                    <label for="mail">Email</label>
                                </span>
                                <small class="p-invalid" v-if="submitted && !data.email">El email es requerido</small>
                            </div>
                          <div class="p-field   p-fluid">
                                <span class="p-float-label">
                                   <InputText id="profession" type="text" v-model="data.profession"
                                               class="p-inputtext-sm"
                                              :disabled="!edit"/>
                                    <label for="profession">Profesión</label>
                                </span>
                            <small class="p-invalid" v-if="submitted && !data.birth_date">La fecha de nacimiento es requerida</small>
                          </div>
                        </div>
                        <div class="p-col-12 p-md-3">
                                <div class="p-field">
                                    <span class="p-float-label">
                                        <input-text id="name" type="text" v-model="data.name"
                                                    :class="{'p-invalid': submitted && !data.name }" class="p-inputtext-sm"
                                                    :disabled="!edit"/>
                                        <label for="name">Nombres</label>
                                    </span>
                                    <small class="p-invalid" v-if="submitted && !data.name">Los nombres son requeridos</small>
                                </div>
                                <div class="p-field">
                                      <span class="p-float-label">
                                        <input-text id="last_name" type="text" v-model="data.last_name"
                                                    :class="{'p-invalid': submitted && !data.last_name }" class="p-inputtext-sm"
                                                    :disabled="!edit"/>
                                        <label for="last_name">Apellidos</label>
                                    </span>
                                    <small class="p-invalid" v-if="submitted && !data.last_name">Los apellidos son requeridos</small>
                                </div>
                        </div>
                        <div class="p-col-12 p-md-3">
                            <div class="p-field   p-fluid">
                                <span class="p-float-label">
                                    <InputText id="phone" type="text" v-model="data.phone"
                                               :class="{'p-invalid': submitted && !data.phone}" class="p-inputtext-sm"
                                               :disabled="!edit"/>
                                    <label for="mail">Teléfono</label>
                                </span>
                                <small class="p-invalid" v-if="submitted && !data.phone">El teléfono es requerido</small>
                            </div>
                            <div class="p-field">
                            <span class="p-float-label">
                                <InputText id="mobile_phone" type="text" v-model="data.mobile_phone" class="p-inputtext-sm" :disabled="!edit"/>
                                <label for="canton">Número de celular</label>
                            </span>
                            </div>

                        </div>
                        <div class="p-col-12 p-md-3">
                            <div class="p-field p-fluid">
                                      <span class="p-float-label">
                                          <Dropdown id="civil_status" v-model="data.civil_status" :options="catalog.civilStatus" :disabled="!edit" :show-clear="true"/>
                                        <label for="civil_status">Estado civil</label>
                                    </span>
                            </div>
                            <div class="p-field   p-fluid">
                                <span class="p-float-label">
                                    <Calendar id="birth_date" :showIcon="true" v-model="data.birth_date" dateFormat="yy-mm-dd"
                                              :yearNavigator="true" yearRange="1920:2050" class="p-inputtext-sm"
                                              :class="{'p-invalid': submitted && (!data.birth_date )  && submitted }" :disabled="!edit"/>
                                    <label for="birth_date">Fecha de nacimiento</label>
                                </span>
                                <small class="p-invalid" v-if="submitted && !data.birth_date">La fecha de nacimiento es requerida</small>
                            </div>
                        </div>
                    </div>
                </Fieldset>
                <Fieldset legend="Datos de domicilio" :toggleable="true" class="p-col-12" v-if="edit">
                  <h4>Principal</h4>
                  <div class="p-grid">
                        <div class="p-col-12 p-md-3">
                            <div class="p-field  p-fluid">
                                <span class="p-float-label">
                                    <AutoComplete id="country" :dropdown="true" :multiple="false" field="name"
                                                  v-model="data.country" :suggestions="countries"  :disabled="!edit"
                                                  @complete="getCatalog($event, 'countries', 'name')"
                                                  :class="{'p-invalid': submitted && (!data.country )}"/>
                                    <label for="country">Pais</label>
                                </span>
                                <small class="p-invalid" v-if="submitted && !data.country">El país es requerido</small>
                            </div>

                            <div class="p-field  p-fluid">
                                <span class="p-float-label">
                                    <AutoComplete id="city" :dropdown="true" :multiple="false" field="name"
                                                  v-model="data.city" :suggestions="cities"  :disabled="!edit"
                                                  @complete="getCatalog($event, 'cities', 'name' , 1)"
                                                  :class="{'p-invalid': submitted && (!data.city )}"/>
                                    <label for="city">Ciudad de domicilio</label>
                                </span>
                                <small class="p-invalid" v-if="submitted && !data.city">La ciudad de domicilio es requerida</small>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-3">
                            <div class="p-field">
                                <span class="p-float-label">
                                    <input-text id="main_street" type="text" v-model="data.main_street" :disabled="!edit" class="p-inputtext-sm"
                                                :class="{'p-invalid': submitted && (!data.main_street )}"/>
                                    <label for="main_street">Calle principal</label>
                                </span>
                                <small class="p-invalid" v-if="submitted && !data.main_street">La calle principal es requerida</small>
                            </div>
                            <div class="p-field">
                                <span class="p-float-label">
                                    <input-text id="second_street" type="text" v-model="data.second_street" :disabled="!edit" class="p-inputtext-sm"/>
                                    <label for="second_street">Calle secundaria</label>
                                </span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-3">
                            <div class="p-field">
                                    <span class="p-float-label">
                                        <input-text id="canton" type="text" v-model="data.canton" :disabled="!edit" class="p-inputtext-sm"/>
                                        <label for="canton">Cantón</label>
                                    </span>
                            </div>

                            <div class="p-field">
                                <span class="p-float-label">
                                    <input-text id="postal_code" type="text" v-model="data.postal_code" class="p-inputtext-sm" :disabled="!edit"/>
                                    <label for="postal_code">Código postal</label>
                                </span>
                            </div>

                        </div>
                        <div class="p-col-12 p-md-3">
                            <div class="p-field">
                                <span class="p-float-label">
                                    <input-text id="house_number" type="text" v-model="data.house_number" class="p-inputtext-sm"
                                                :disabled="!edit"/>
                                    <label for="canton">Número de casa</label>
                                </span>
                            </div>
                            <div class="p-field p-fluid">
                                     <span class="p-float-label">
                                          <Dropdown id="type_housing" v-model="data.type_housing" :options="typeHousing" :disabled="!edit" :show-clear="true"  optionLabel="label" optionValue="value" />
                                        <label for="type_housing">Tipo de vivienda</label>
                                    </span>
                            </div>
                        </div>
                    </div>
                  <DataTable
                      :value="additionalAddresses"
                      class="p-datatable-responsive-demo p-datatable-sm"
                      v-model:selection="currentItemAdditionalAddress"
                      selectionMode="single"
                      dataKey="id"
                      :paginator="true"
                      paginatorPosition="bottom"
                      :rows="10"
                  >
                    <template #empty>
                      <div class="p-grid p-col-12">
                        <p>No existen registros de direcciones secundarias.</p>
                      </div>
                    </template>

                    <template #header>
                      <div class="p-grid p-col-12 p-justify-between">
                        <h5 class="p-m-1">Secundarios</h5>
                        <div>
                          <Button
                              label="Agregar"
                              icon="pi pi-plus"
                              class="p-button-success p-m-1"
                              @click="openNewAdditionalAddress"
                          />
                        </div>
                      </div>
                    </template>
                    <Column field="id" header="País" >
                      <template #body="slotProps">
                        <span class="p-column-value">
                        {{ slotProps.data.country.name }}
                      </span>
                      </template>
                    </Column>
                    <Column field="name" header="Ciudad" >
                      <template #body="slotProps">

                        <span class="p-column-value">
                        {{ slotProps.data.city.name }}
                      </span>
                      </template>
                    </Column>
                    <Column field="code" header="Cantón" >
                      <template #body="slotProps">
                        <span class="p-column-value">
                        {{ slotProps.data.canton }}
                      </span>
                      </template>
                    </Column>
                    <Column field="country.name" header="Calle principal" >
                      <template #body="slotProps">
                        <span class="p-column-value">
                        {{ slotProps.data.main_street }}
                      </span>
                      </template>
                    </Column>

                    <Column field="country.name" header="Calle secundaria">
                      <template #body="slotProps">
                        <span class="p-column-value">
                        {{ slotProps.data.second_street }}
                      </span>
                      </template>
                    </Column>
                    <Column field="country.name" header="Código postal" >
                      <template #body="slotProps">
                        <span class="p-column-value">
                        {{ slotProps.data.postal_code }}
                      </span>
                      </template>
                    </Column>
                    <Column field="country.name" header="Número de casa" >
                      <template #body="slotProps">
                        <span class="p-column-value">
                        {{ slotProps.data.house_number }}
                      </span>
                      </template>
                    </Column>
                    <Column field="country.name" header="Tipo de vivienda" >
                      <template #body="slotProps">
                        <span class="p-column-value">
                        {{ slotProps.data.type_housing_name }}
                      </span>
                      </template>
                    </Column>
                    <Column header="Opciones">
                      <template #body="slotProps">

                        <Button
                            icon="pi pi-pencil"
                            class="p-button-rounded p-button-success p-mr-2"
                            @click="editRow(slotProps.data)"
                        />
                        <Button
                            icon="pi pi-trash"
                            class="p-button-rounded p-button-warning"
                            @click="deleteRow(slotProps.data, slotProps.index)"
                        />
                      </template>
                    </Column>
                  </DataTable>
                  <Dialog
                      v-model:visible="formDialog"
                      :style="{ width: '28.125rem' }"
                      header="Dirección adicional"
                      :modal="true"
                      class="p-fluid"
                  >

                    <br/>
                    <div class="p-field p-fluid" v-if="currentItemAdditionalAddress">
                        <span class="p-float-label">
                                    <AutoComplete
                                        id="count"
                                        :dropdown="true"
                                        :multiple="false"
                                        field="name"
                                        v-model="currentItemAdditionalAddress.country"
                                        :suggestions="countries"
                                        @complete="getCatalogAdditionalAddress($event, 'countries', 'name')"
                                        :class="{ 'p-invalid': submitted && !currentItemAdditionalAddress.country }"
                                    />
                                    <label for="count">País</label>
                        </span>
                         <small class="p-invalid" v-if="submitted && !currentItemAdditionalAddress.country">El país es requerido</small>
                    </div>
                    <br/>
                    <div class="p-field p-fluid" v-if="currentItemAdditionalAddress">
                          <span class="p-float-label">
                            <AutoComplete
                                id="count"
                                :dropdown="true"
                                :multiple="false"
                                field="name"
                                v-model="currentItemAdditionalAddress.city"
                                :suggestions="cities"
                                @complete="getCatalogAdditionalAddress($event, 'cities', 'name', 1)"
                                :class="{ 'p-invalid': submitted && !currentItemAdditionalAddress.city }"
                            />
                            <label for="count">Ciudad</label>
                          </span>
                              <small class="p-invalid" v-if="submitted && !currentItemAdditionalAddress.city">El ciudad es requerido</small>
                            </div>
                    <br/>
                    <div class="p-field p-fluid" v-if="currentItemAdditionalAddress">
                            <span class="p-float-label">
                                    <input-text id="main_street" type="text" v-model="currentItemAdditionalAddress.main_street" :disabled="!edit" class="p-inputtext-sm"
                                                :class="{'p-invalid': submitted && (!currentItemAdditionalAddress.main_street )}"/>
                                    <label for="main_street">Calle principal</label>
                                </span>
                      <small class="p-invalid" v-if="submitted && !currentItemAdditionalAddress.main_street">La calle principal es requerida</small>
                    </div>

                    <div class="p-field p-fluid" v-if="currentItemAdditionalAddress">
                            <span class="p-float-label">
                                    <input-text id="second_street" type="text" v-model="currentItemAdditionalAddress.second_street" :disabled="!edit" class="p-inputtext-sm"
                                                :class="{'p-invalid': submitted && (!currentItemAdditionalAddress.second_street )}"/>
                                    <label for="second_street">Calle secundaria</label>
                                </span>
                      <small class="p-invalid" v-if="submitted && !currentItemAdditionalAddress.second_street">La calle secundaria es requerida</small>
                    </div>

                    <div class="p-field p-fluid" v-if="currentItemAdditionalAddress">
                              <span class="p-float-label">
                                        <input-text id="canton" type="text" v-model="currentItemAdditionalAddress.canton" :disabled="!edit" class="p-inputtext-sm"/>
                                        <label for="canton">Cantón</label>
                              </span>
                    </div>

                    <div class="p-field p-fluid" v-if="currentItemAdditionalAddress">
                       <span class="p-float-label">
                                    <input-text id="postal_code" type="text" v-model="currentItemAdditionalAddress.postal_code" class="p-inputtext-sm" :disabled="!edit"/>
                                    <label for="postal_code">Código postal</label>
                       </span>
                    </div>

                    <div class="p-field p-fluid" v-if="currentItemAdditionalAddress">
                       <span class="p-float-label">
                                    <input-text id="house_number" type="text" v-model="currentItemAdditionalAddress.house_number" class="p-inputtext-sm"
                                                :disabled="!edit"/>
                                    <label for="canton">Número de casa</label>
                                </span>
                    </div>
                    <div class="p-field p-fluid" v-if="currentItemAdditionalAddress">
                          <span class="p-float-label">
                                          <Dropdown id="type_housing" v-model="currentItemAdditionalAddress.type_housing" :options="typeHousing" :disabled="!edit" :show-clear="true" optionLabel="label" optionValue="value" />
                                        <label for="type_housing">Tipo de vivienda</label>
                                    </span>
                    </div>

                    <template #footer>
                      <Button
                          label="Cancel"
                          icon="pi pi-times"
                          class="p-button-text"
                          @click="hideDialog"
                      />
                      <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveItemAdditinalAddress"/>
                    </template>
                  </Dialog>
                </Fieldset>
                <Fieldset legend="Datos laborales" :toggleable="true" class="p-col-12" v-if="edit">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-3">
                            <div class="p-field">
                                <span class="p-float-label">
                                    <InputText id="company_name" type="text" v-model="data.company_name" class="p-inputtext-sm" :disabled="!edit"
                                               :class="{'p-invalid': submitted && (!data.company_name )}"/>
                                    <label for="company_name">Nombre de la empresa</label>
                                </span>
                                <small class="p-invalid" v-if="submitted && !data.company_name">La empresa es requerida</small>
                            </div>
                            <div class="p-grid">
                                <div class="p-field p-col-6 p-col-md-6">
                                <span class="p-float-label">
                                    <InputText id="company_phone" type="text" v-model="data.company_phone" class="p-inputtext-sm" :disabled="!edit"
                                               :class="{'p-invalid': submitted && (!data.company_phone )}"/>
                                    <label for="company_phone">Teléfono</label>
                                </span>
                                    <small class="p-invalid" v-if="submitted && !data.company_phone">El teléfono de la empresa es requerido</small>
                                </div>
                                <div class="p-field p-col-6 p-col-md-6">
                                <span class="p-float-label">
                                    <InputText id="company_phone_extension" type="number" v-model="data.company_phone_extension" class="p-inputtext-sm" :disabled="!edit"
                                               :class="{'p-invalid': submitted && (!data.company_phone_extension )}"/>
                                    <label for="company_phone_extension">Extensión</label>
                                </span>
                                    <small class="p-invalid" v-if="submitted && !data.company_phone_extension">La extensión se requiere</small>
                                </div>
                            </div>

                            <div class="p-field">
                                <span class="p-float-label">
                                    <InputText id="company_email" type="text" v-model="data.company_email" class="p-inputtext-sm" :disabled="!edit"
                                               :class="{'p-invalid': submitted && (!data.company_email )}"/>
                                    <label for="company_email">Email de la empresa</label>
                                </span>
                                <small class="p-invalid" v-if="submitted && !data.company_email">El email de la empresa es requerido</small>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-3">
                            <div class="p-field">
                                <span class="p-float-label">
                                    <InputText id="company_address" type="text" v-model="data.company_address" class="p-inputtext-sm" :disabled="!edit"
                                               :class="{'p-invalid': submitted && (!data.company_address )}"/>
                                    <label for="company_address">Dirección de la empresa</label>
                                </span>
                                <small class="p-invalid" v-if="submitted && !data.company_address">La dirección de la empresa es requerida</small>

                            </div>
                            <div class="p-field p-fluid">
                                     <span class="p-float-label">
                                          <Dropdown id="type_employee" v-model="data.type_employee" :options="typeEmployees" :disabled="!edit" :show-clear="true" optionLabel="label" optionValue="value" />
                                        <label for="type_employee">Tipo de empleado</label>
                                    </span>
                            </div>
                            <div class="p-field   p-fluid">
                                <span class="p-float-label">
                                    <Calendar id="date_admission" :showIcon="true" v-model="data.date_admission" dateFormat="yy-mm-dd"
                                              :yearNavigator="true" yearRange="1980:2050" class="p-inputtext-sm"/>
                                    <label for="date_admission">Fecha de ingreso</label>
                                </span>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-3">
                            <div class="p-field">
                                <span class="p-float-label">
                                    <InputText id="position" type="text" v-model="data.position" class="p-inputtext-sm" :disabled="!edit"/>
                                    <label for="position">Cargo</label>
                                </span>
                            </div>
                            <div class="p-field">
                            <span class="p-float-label">
                                <InputText id="facebook" type="text" v-model="data.facebook" class="p-inputtext-sm" :disabled="!edit"/>
                                <label for="facebook">Facebook</label>
                            </span>
                            </div>
                            <div class="p-field">
                            <span class="p-float-label">
                                <InputText id="skype" type="text" v-model="data.skype" class="p-inputtext-sm" :disabled="!edit"/>
                                <label for="skype">Skype</label>
                            </span>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-3">
                            <div class="p-field">
                                <span class="p-float-label">
                                    <InputText id="monthly_income"  v-model="data.monthly_income" type="number" step="0.01" class="p-inputtext-sm" :disabled="!edit"
                                               :class="{'p-invalid': submitted && (!data.monthly_income )}"/>
                                    <label for="monthly_income">Ingreso mensual</label>
                                </span>
                                <small class="p-invalid" v-if="submitted && !data.monthly_income">El ingreso mensual es requerido</small>
                            </div>
                            <div class="p-field">
                                <span class="p-float-label">
                                    <Textarea id="observation" v-model="data.observation" class="p-inputtext-sm" rows="4" :disabled="!edit"/>
                                    <label for="observation">Observación</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </Fieldset>
            </div>
        </TabPanel>
        <TabPanel header="Referencias bancarias" :disabled="!edit">
            <bank-references :holder="holder" v-if="holder && edit"></bank-references>
        </TabPanel>
        <TabPanel header="Referencias personales" :disabled="!edit">
            <personal-references :holder="holder" v-if="holder &&  edit"></personal-references>
        </TabPanel>
        <TabPanel header="Codeudores" :disabled="!edit">
            <cosigners :holder="holder" v-if="holder && edit"></cosigners>
        </TabPanel>
    </TabView>


</template>

<script>
    import service from '../../service/holder.service'
    import dataService from '../../service/data.service'
    import catalogueService from "../../../core/service/catalog.service";
    import moment from "moment";
    import {defineAsyncComponent} from "vue";

    export default {
        name: "Holder",
        components: {
            'bank-references': defineAsyncComponent(() => import('./BankReferences')),
            'personal-references': defineAsyncComponent(() => import('./PersonalReferences')),
            'cosigners': defineAsyncComponent(() => import('./Cosigners')),
        },
        props: {
            holder: Object,
            edit: Boolean,
            typeEmployees: Array,
            typeHousing: Array
        },
        emits: ['ok', 'cancel'],
        data() {
            return {
                data: {},
                catalog: dataService,
                countries: [],
                cities: [],
                submitted: false,
                loading: false,
                additionalAddresses: [],
                formDialog: false,
                currentItemAdditionalAddress: null,
                isNewAdditionalAddress : false,
            }
        },
        methods: {
            formatData() {
                const params = {...this.data};
                params.city_id = params.city.id;
                params.country_id = params.country.id;
                params.date_admission = moment(params.date_admission? params.date_admission: undefined).format("YYYY-MM-DD");
                params.birth_date = moment(params.birth_date ).format("YYYY-MM-DD");
                params.additionalAddresses = this.additionalAddresses !== undefined? this.additionalAddresses : null;
                return params;
            },
            save() {
                return new Promise((resolve, reject) =>  {
                    this.submitted = true;
                    if (!this.data.name || !this.data.last_name || !this.data.dni || !this.data.email || !this.data.phone || !this.data.country || !this.data.city || !this.data.company_phone_extension ||
                        !this.data.birth_date  || !this.data.main_street || !this.data.monthly_income ||  !this.data.company_address || !this.data.company_name || !this.data.company_phone || !this.data.company_email) {
                        reject();
                        return null;
                    }
                    this.loading = true;
                    const params = this.formatData();
                    service.save(params, this.data.id).then(() => {
                        this.loading = false;
                        resolve();
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Registrado correctamente',
                            life: this.$utils.toastLifeTime()
                        })
                      if(this.data.id) {
                        this.getHolderDirectionAdditionalByHolderId(this.data.id);
                      }
                      this.isNewAdditionalAddress = true;
                    }).catch((err) => {
                        reject();
                        this.loading = false;
                        const message = err.response.data;
                        let error = this.$utils.formatError(message);
                        this.$toast.add({
                            severity: 'error',
                            summary: error,
                            life: this.$utils.toastLifeTime()
                        })
                    }).finally(() => {
                    });
                })
            }
            ,
            cancel() {
                this.$emit('cancel')
            },
            getCatalog(event, route, field, country) {
                const originalRoute = route;
                if (country) {
                    if (!this.data.country) {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Pais no seleccionado',
                            detail: 'Por favor seleccione un pais',
                            life: this.$utils.toastLifeTime()
                        });
                        return;
                    }
                    route = `countries/${this.data.country.id}/${route}`;
                }

                catalogueService.getCatalog(event, route, field).then(x => {
                    this[originalRoute] = x;
                }).catch(() => {
                    this[originalRoute] = [];
                });
            },

          getHolderDirectionAdditionalByHolderId(id) {
            this.$nextTick(() => {
              service.getHolderDirectionAdditionalByHolderId(id).then(x => {
                this.additionalAddresses = x.data;

              })
            });
          },

          openNewAdditionalAddress() {
            this.formDialog = true;
            this.currentItemAdditionalAddress = {};
            this.isNewAdditionalAddress = true;
          },

          getCatalogAdditionalAddress(event, route, field, country) {
            const originalRoute = route;
            if (country) {
              if (!this.currentItemAdditionalAddress.country) {
                this.$toast.add({
                  severity: 'warn',
                  summary: 'Pais no seleccionado',
                  detail: 'Por favor seleccione un pais',
                  life: this.$utils.toastLifeTime()
                });
                return;
              }
              route = `countries/${this.currentItemAdditionalAddress.country.id}/${route}`;
            }

            catalogueService.getCatalog(event, route, field).then(x => {
              this[originalRoute] = x;
            }).catch(() => {
              this[originalRoute] = [];
            });
          },

          saveItemAdditinalAddress() {

            if (this.additionalAddresses === undefined) {
              this.additionalAddresses = [];
            }
            this.submitted = true;
            if (
                !this.currentItemAdditionalAddress.city ||
                !this.currentItemAdditionalAddress.country
            ) {
              return;
            }
            let type_housing_name = null;
            if(this.currentItemAdditionalAddress.type_housing !== undefined) {
               let type = this.typeHousing.find(item => item.value === this.currentItemAdditionalAddress.type_housing);
              type_housing_name = type !== undefined ? type.label: '';
            }
            this.currentItemAdditionalAddress.country_id = this.currentItemAdditionalAddress.country.id;
            this.currentItemAdditionalAddress.city_id = this.currentItemAdditionalAddress.city.id;
            this.currentItemAdditionalAddress.type_housing_name = type_housing_name;
            if(this.isNewAdditionalAddress) {
              this.additionalAddresses.push(this.currentItemAdditionalAddress);
            }
            this.formDialog = false;
          },
          hideDialog() {
            this.currentItemAdditionalAddress = {};
            this.formDialog = false;
          },

          deleteRow(row, index) {
            if (row.id) {
              service
                  .deleteHolderDirectionAdditionalByHolderId(row.id)
                  .then(() => {
                    this.$toast.add({
                      severity: "success",
                      summary: "Registro eliminado",
                      detail: "Se eliminó correctamente",
                      life: this.$utils.toastLifeTime(),
                    });
                    this.additionalAddresses.splice(index, 1);
                  });
            } else {
              this.additionalAddresses.splice(index, 1);
            }

          },
          editRow(item) {
            this.currentItemAdditionalAddress = item;
            this.formDialog = true;
            this.isNewAdditionalAddress = false;
          },

        },
        mounted() {
            this.data = this.holder ? this.holder : {};
            if(this.data.id) {
              this.getHolderDirectionAdditionalByHolderId(this.data.id);
            }
           this.isNewAdditionalAddress = true;
        }
    }
</script>

<style scoped lang="scss">

    .p-fieldset {
        margin-top: 5px;
    }

    .p-field {
        padding-top: 10px;
    }

    .p-dialog-footer {
        padding: 0;
        margin-bottom: -15px;
    }

    .p-grid {
        margin-top: 5px;
    }

    .p-inputtext {
        width: 100%;
    }
    .p-calendar{
        height: 30px !important;
    }
    .p-dropdown  {
        height: 30px !important;
    }
    .p-autocomplete {
        height: 30px !important;
    }
</style>
